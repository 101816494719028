/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /* =============================================================================
           smartmenus
           ========================================================================== */

        // $(function() {
        //   $('#main-menu').smartmenus({
        //     subMenusMinWidth: '200px',
        //     subMenusMaxWidth: '300px',
        //     subIndicators:    false,
        //     keepHighlighted:  false,
        //     markCurrentItem:  true
        //   });
        // });

        // $(function() {
        //   $('#menu-button').click(function() {
        //     var $this = $(this),
        //         $menu = $('#main-menu');
        //     if (!$this.hasClass('collapsed')) {
        //       $menu.addClass('collapsed');
        //       $this.addClass('collapsed');
        //     } else {
        //       $menu.removeClass('collapsed');
        //       $this.removeClass('collapsed');
        //     }
        //     return false;
        //   }).click();
        // });



        // Responsive Dropdown Navigation Bar
        // https://gist.github.com/taniarascia/56893ff29f64038dca91
        // If a link has a dropdown, add sub menu toggle.
        // Responsive Dropdown Navigation Bar
        // https://gist.github.com/taniarascia/56893ff29f64038dca91
        // If a link has a dropdown, add sub menu toggle.
        $('.nav-primary li a:not(:only-child)').click(function(e) {
          $(this).siblings('.sub-menu').toggle();
          // Close one dropdown when selecting another
          $('.sub-menu').not($(this).siblings()).hide();
          e.stopPropagation();
        });
        // Clicking away from dropdown will remove the dropdown class
        $('html').click(function() {
          $('.sub-menu').hide();
        });
        // Clicking a link closes the menu - page scroll
        // $('nav ul li a').click(function() {
        //   $('nav ul').slideToggle();
        // });
        // Toggle open and close nav styles on click
        $('#nav-toggle').click(function() {
          $('.nav-primary').slideToggle();
        });
        // Hamburger to X toggle
        $('#nav-toggle').on('click', function() {
          this.classList.toggle('active');
        });



        /* =============================================================================
           Use magnificPopup with default gallery.
           +
           Get the columns working!!
           https://clicknathan.com/web-design/css-for-all-available-wordpress-gallery-columns/
           ========================================================================== */

        $('a[href*=".jpg"], a[href*=".jpeg"], a[href*=".png"], a[href*=".gif"]').each(function(){

          if ($(this).parents('.gallery').length == 0) {
          $(this).magnificPopup({
          type:'image',
          closeOnContentClick: true,
          mainClass: 'mfp-with-zoom', // this class is for CSS animation below

          image: {
          markup: '<div class="mfp-figure">'+
          '<div class="mfp-close"></div>'+
          '<div class="mfp-img"></div>'+
          '<div class="mfp-bottom-bar">'+
          '<div class="mfp-title"></div>'+
          '<div class="mfp-description" style="text-align: left;font-size: 12px;line-height: 16px;color: #f3f3f3;word-break: break-word;padding-right: 36px;"></div>'+
          '<div class="mfp-counter"></div>'+
          '</div>'+
          '</div>',
          titleSrc: function(item) {
          return '<strong>' + item.el.find('img').attr('alt') + '</strong>';
          }
          },

          zoom: {
          enabled: true,
          duration: 300,
          easing: 'ease-in-out',
          // By default it looks for an image tag:
          opener: function(openerElement) {
          return openerElement.is('img') ? openerElement : openerElement.find('img');}
          }

          });
          }
          });

          // initialize magnific popup galleries with titles and descriptions
          $('.gallery').magnificPopup({
          callbacks: {
          open: function() {
          $('.mfp-description').append(this.currItem.el.attr('title'));
          },
          afterChange: function() {
          $('.mfp-description').empty().append(this.currItem.el.attr('title'));
          }
          },
          delegate: 'a',
          type: 'image',
          image: {
          markup: '<div class="mfp-figure">'+
          '<div class="mfp-close"></div>'+
          '<div class="mfp-img"></div>'+
          '<div class="mfp-bottom-bar">'+
          '<div class="mfp-title"></div>'+
          '<div class="mfp-description" style="text-align: left;font-size: 12px;line-height: 16px;color: #f3f3f3;word-break: break-word;padding-right: 36px;"></div>'+
          '<div class="mfp-counter"></div>'+
          '</div>'+
          '</div>',
          titleSrc: function(item) {
          return '<strong>' + item.el.find('img').attr('alt') + '</strong>';
          }
          },
          gallery: {
          enabled: true,
          navigateByImgClick: true
          }

        });


        /**
        *
        * jsSocials - Simple Social Sharing
        * https://github.com/tabalinas/jssocials
        *
        */

        $("#share").jsSocials({
            shares: // ["twitter", "facebook", "pinterest", "email"],
            [
              {
                share: "twitter",
                logo: "/app/themes/gfh/templates/svg/icon-twitter--dark.svg",
              },
              {
                share: "facebook",
                logo: "/app/themes/gfh/templates/svg/icon-facebook--dark.svg",
              },
            ],
            showLabel: false,
            showCount: false,
            shareIn: "popup"
        });

        // $("#share").jsSocials({
        //     shares: ["email", "twitter", "facebook", "googleplus", "linkedin", "pinterest", "stumbleupon", "whatsapp"]
        // });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
